<!--
 * @Description: 驱动管理 - 任务列表
 * @Author: kecraft
 * @Date: 2024-02-28 09:07:09
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:12:54
 * @FilePath: /impact-iotos-console/src/views/drive/task/taskList.vue
-->
<template>
  <div class="task-list">
    <div>
      <!-- 刷新 -->
      <div class="search-container">
        <div class="search-btn" @click="search" v-domPreventReClick>刷新</div>
      </div>
      <!-- 内容区域 -->
      <div class="table-container">
        <div class="collapse-item" v-for="(item, index) of taskGroup" :key="index">
          <div class="title-container">
            <div class="title-left">
              <div class="deployMode1" v-if="item.driver.deployMode == 'STANDALONE'">{{ dealStr(item) || "-" }}</div>
              <div class="deployMode2" v-if="item.driver.deployMode == 'CLUSTER'">{{ dealStr(item) || "-" }}</div>
              <div class="deployMode3" v-if="item.driver.deployMode == 'BROADCAST'">{{ dealStr(item) || "-" }}</div>
              <div>{{ item && item.driver && item.driver.name || "-" }}</div>
            </div>
            <div class="title-right">
              <span class="gary-btn">总数 {{ item.count || 0 }}/</span>
              <span class="green-btn">运行 {{ item.runningCount || 0 }}</span>
              <img src="@/assets/newicon/up.png" v-if="item.isOpen" class="isOpen" @click="() => item.isOpen = false"
                v-domPreventReClick>
              <img src="@/assets/newicon/down.png" v-else @click="() => item.isOpen = true" v-domPreventReClick>
            </div>
          </div>
          <div class="main-box" v-if="!item.isOpen && item.tasks && item.tasks.length > 0">
            <div v-for="(task, taskIndex) of item.tasks" :key="taskIndex">
              <div class="table-headers">
                <div>序号</div>
                <div>驱动名称</div>
                <div>驱动版本</div>
                <div>所在机器</div>
                <div>开启时间</div>
                <div>停止时间</div>
                <div>运行状态</div>
                <div>进程pid</div>
                <div>操作</div>
              </div>
              <div class="table-bodys">
                <div>{{ index + 1 }}</div>
                <div>{{ task.name }}</div>
                <div> {{ task && task.driver && task.driver.version || "-" }}</div>
                <div>{{ task.applicationInstanceId }}</div>
                <div>{{ task.startTime }}</div>
                <div>{{ task.stopTime }}</div>
                <div>
                  <div class="status-container" v-if="!task.running">
                    <div class="icon"></div>
                    <div>停止</div>
                  </div>
                  <div class="status-container" v-else>
                    <div class="icon1"></div>
                    <div>运行</div>
                  </div>
                </div>
                <div>{{ task.pid }}</div>
                <div>
                  <div class="column-btn">
                    <div class="options">
                      <span link style="color:#367CC5;cursor: pointer;padding: 0 6px;" @click="handleEdit(task)"
                        v-domPreventReClick>
                        编辑
                      </span>
                      <span link style="color:#367CC5;cursor: pointer;padding: 0 6px;" @click="handleStop(task)"
                        v-if="task.running" v-domPreventReClick>
                        停止
                      </span>
                      <span link style="color:#367CC5;cursor: pointer;padding: 0 6px;" @click="handleRun(task)"
                        v-if="!task.running" v-domPreventReClick>
                        启动
                      </span>
                      <span link style="color:#367CC5;cursor: pointer;padding: 0 6px;" @click="handleReload(task)"
                        v-domPreventReClick>
                        重启
                      </span>
                      <span link style="color:#367CC5;cursor: pointer;padding: 0 6px;" @click="handleLook(task)"
                        v-domPreventReClick>
                        查看
                      </span>
                      <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleDel(task)"
                        v-domPreventReClick>
                        删除
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <taskAdd v-if="isEdit" @close="handleClose" ref="taskAddRef" />
    </div>
  </div>
</template>

<script setup>
import taskAdd from "./taskAdd";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref, getCurrentInstance, nextTick, onMounted } from 'vue';
import api from "@/api/api"
import { useRouter } from "vue-router";
import { ElLoading } from 'element-plus';
const router = useRouter();
const taskGroup = ref([]);
const isEdit = ref(false);
const activeNames = ref([]);
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
})
const search = () => {
  api.getAllTaskGroups().then(res => {
    if (res.code === "0") {
      let hasDriver = [];
      const list = res.res.data;
      list.forEach(item => {
        hasDriver.push(item.driver.id)
      })
      activeNames.value = hasDriver;
      taskGroup.value = res.res.data;
    }
  })
}
search();
const enums = [
  {
    label: "单机模式",
    value: "STANDALONE"
  },
  {
    label: "集群模式",
    value: "CLUSTER"
  },
  {
    label: "广播模式",
    value: "BROADCAST"
  }
]
const dealStr = (item) => {
  let str = ""
  if (item && item.driver && item.driver.deployMode) {
    const mode = item.driver.deployMode;
    enums.forEach(e => {
      if (mode == e.value) {
        str = e.label;
      }
    })
  }
  return str
}
const handleEdit = (row) => {
  isEdit.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.taskAddRef.initEdit(row);
  });
}
const handleStop = (row) => {
  ElMessageBox.confirm("确定停止该任务吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    api.stopTask(row.id,).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("停止成功!")
        search()
      }
      loading.close();
    })
  })
}
const handleReload = (row) => {
  ElMessageBox.confirm("确定重启该任务吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    api.restartTask(row.id).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("重启成功!")
        search()
      }
      loading.close();
    })
  })
}
const handleLook = (row) => {
  const { name, id } = row
  router.push({
    path: "/drive/task/details",
    query: {
      name,
      taskId: id
    }
  })
}
const handleRun = (row) => {
  ElMessageBox.confirm("确定启动该任务吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    api.startTask(row.id).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("启动成功!")
        search()
      }
    })
    loading.close();
  })
}
const handleDel = (row) => {
  ElMessageBox.confirm("确定删除该任务吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.taskDelectById(row.id).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("删除成功!")
        search()
      }
    })
  })
}

const handleClose = (val) => {
  isEdit.value = false;
  if (val) {
    search()
  }
}
</script>

<style lang="less" scoped>
.task-list {
  box-sizing: border-box;
  background: #FAFAFB;
  padding: 20px 50px;
  min-height: calc(100vh - 66px);

  .search-container {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;

    &>div {
      margin-left: 10px;
    }
  }

  .table-container {
    box-sizing: border-box;
    margin-top: 20px;
    // padding: 20px;
  }
}

.collapse-item {
  box-sizing: border-box;
  padding-bottom: 0;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #E6E8EC;
  margin-bottom: 10px;

  .main-box {
    box-sizing: border-box;
    padding: 0 40px;

    .table-headers {
      display: flex;
      align-items: center;
      height: 50px;

      &>div {
        display: inline-block;
        text-align: center;
        font-size: 16px;
        color: #000000;
      }

      &>div:nth-child(1) {
        width: 5%;
      }

      &>div:nth-child(2) {
        width: 20%;
      }

      &>div:nth-child(3) {
        width: 5%;
      }

      &>div:nth-child(4) {
        width: 10%;
      }

      &>div:nth-child(5) {
        width: 15%;
      }

      &>div:nth-child(6) {
        width: 15%;
      }

      &>div:nth-child(7) {
        width: 10%;
      }

      &>div:nth-child(8) {
        width: 5%;
      }

      &>div:nth-child(9) {
        width: 20%;
      }
    }

    .table-bodys {
      display: flex;
      align-items: center;
      height: 50px;
      border-top: 1px dashed #979797;

      &>div {
        text-align: center;
        font-size: 16px;
        color: #606266;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
      }

      &>div:nth-child(1) {
        width: 5%;
      }

      &>div:nth-child(2) {
        width: 20%;
      }

      &>div:nth-child(3) {
        width: 5%;
      }

      &>div:nth-child(4) {
        width: 10%;
      }

      &>div:nth-child(5) {
        width: 15%;
      }

      &>div:nth-child(6) {
        width: 15%;
      }

      &>div:nth-child(7) {
        width: 10%;
      }

      &>div:nth-child(8) {
        width: 5%;
      }

      &>div:nth-child(9) {
        width: 20%;
      }
    }
  }
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 40px;
  border-bottom: 1px solid #E6E8EC;

  .title-left {
    font-size: 20px;
    display: flex;
    align-items: center;
    flex: 1;

    .deployMode1 {
      width: 72px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: #788194;
      background: #EBEFF6;
      margin-right: 15px;
    }

    .deployMode2 {
      width: 72px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: #8F46EB;
      background: #EFE5FB;
      margin-right: 15px;
    }

    .deployMode3 {
      width: 72px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: #7F765C;
      background: #F1E890;
      margin-right: 15px;
    }
  }

  .title-right {
    display: flex;
    align-items: center;

    img {
      width: 10px;
      height: 6px;
      margin-left: 30px;
    }

    .isOpen {
      transform: rotate(90deg);
    }
  }

  .green-btn {
    font-size: 14px;
    color: #30AE7D;
  }

  .gary-btn {
    font-size: 14px;
    display: inline-block;
    color: #788194;
  }
}

.search-btn {
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background: #367CC5;
  font-weight: lighter;
  // border: 1px solid #367CC5;
  font-size: 14px;
  color: #FFFFFF;
  font-size: 16px;
}

.search-btn:hover,
.search-btn:active {
  border: 1px solid #367CC5;
  background: #61A4E9;
  // color: #367CC5;
}

.tables {
  box-sizing: border-box;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #878CAA;
  box-sizing: border-box;
  margin-right: 10px;
  background: rgba(135, 140, 170, 0.5);
}

.icon::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #878CAA;
}

.icon1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #0EB463;
  box-sizing: border-box;
  margin-right: 10px;
  background: rgba(14, 180, 99, 0.5);
}

.icon1::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0EB463;
}

.icon1::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0eb463;
}
</style>